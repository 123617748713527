import type { PageView, ViewsReport } from './types';

export function generateViewsReport(pageViews: PageView[], days: number): ViewsReport {
  const cutoff = new Date();
  cutoff.setDate(cutoff.getDate() - days);
  
  const recentViews = pageViews.filter(view => 
    new Date(view.timestamp) > cutoff
  );

  const byPage: Record<string, number> = {};
  const byDevice: Record<string, number> = {};
  const byReferrer: Record<string, number> = {};

  recentViews.forEach(view => {
    byPage[view.path] = (byPage[view.path] || 0) + 1;
    byDevice[view.deviceType] = (byDevice[view.deviceType] || 0) + 1;
    byReferrer[view.referrer || 'direct'] = (byReferrer[view.referrer || 'direct'] || 0) + 1;
  });

  return {
    total: recentViews.length,
    unique: new Set(recentViews.map(v => v.sessionId)).size,
    byPage,
    byDevice,
    byReferrer
  };
}