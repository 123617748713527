import { AnalyticsData } from './types';
import { logger } from '../logger';

export function loadAnalyticsFromStorage(): Partial<AnalyticsData> {
  try {
    const stored = localStorage.getItem('analytics');
    if (stored) {
      const parsed = JSON.parse(stored);
      return {
        pageViews: parsed.pageViews || [],
        uniqueVisitors: new Set(parsed.uniqueVisitors || []),
        sessions: new Set(parsed.sessions || [])
      };
    }
  } catch (error) {
    logger.error('Failed to load analytics data:', error);
  }
  return {};
}

export function saveAnalyticsToStorage(data: AnalyticsData): void {
  try {
    localStorage.setItem('analytics', JSON.stringify({
      pageViews: data.pageViews,
      uniqueVisitors: Array.from(data.uniqueVisitors),
      sessions: Array.from(data.sessions)
    }));
  } catch (error) {
    logger.error('Failed to save analytics data:', error);
  }
}