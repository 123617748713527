import { PageView, AnalyticsData, ViewsReport } from './types';
import { loadAnalyticsFromStorage, saveAnalyticsToStorage } from './storage';
import { getVisitorId, getSessionId } from './identity';
import { getDeviceType } from './device';
import { generateViewsReport } from './reporting';
import { setupAnalyticsCleanup } from './cleanup';
import { logger } from '../logger';

class AnalyticsService {
  private static instance: AnalyticsService;
  private data: AnalyticsData = {
    pageViews: [],
    uniqueVisitors: new Set(),
    sessions: new Set()
  };

  private constructor() {
    const stored = loadAnalyticsFromStorage();
    Object.assign(this.data, stored);
    
    setupAnalyticsCleanup(
      () => this.data.pageViews,
      (views) => this.data.pageViews = views
    );
  }

  static getInstance(): AnalyticsService {
    if (!AnalyticsService.instance) {
      AnalyticsService.instance = new AnalyticsService();
    }
    return AnalyticsService.instance;
  }

  public recordPageView(path: string): void {
    const visitorId = getVisitorId();
    const sessionId = getSessionId();
    
    const pageView: PageView = {
      path,
      timestamp: new Date().toISOString(),
      sessionId,
      deviceType: getDeviceType(),
      referrer: document.referrer
    };

    this.data.pageViews.push(pageView);
    this.data.uniqueVisitors.add(visitorId);
    this.data.sessions.add(sessionId);
    
    saveAnalyticsToStorage(this.data);
    logger.info('Page view recorded:', pageView);
  }

  public getViewsReport(days: number = 14): ViewsReport {
    return generateViewsReport(this.data.pageViews, days);
  }
}

export const analyticsService = AnalyticsService.getInstance();