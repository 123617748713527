import type { PageView } from './types';
import { saveAnalyticsToStorage } from './storage';
import { logger } from '../logger';

export function setupAnalyticsCleanup(
  getPageViews: () => PageView[],
  setPageViews: (views: PageView[]) => void
): () => void {
  const intervalId = setInterval(() => {
    try {
      const cutoff = new Date();
      cutoff.setDate(cutoff.getDate() - 90);
      
      const filteredViews = getPageViews().filter(view => 
        new Date(view.timestamp) > cutoff
      );
      
      setPageViews(filteredViews);
      saveAnalyticsToStorage({
        pageViews: filteredViews,
        uniqueVisitors: new Set(),
        sessions: new Set()
      });
    } catch (error) {
      logger.error('Analytics cleanup failed:', error);
    }
  }, 24 * 60 * 60 * 1000); // Run daily

  return () => clearInterval(intervalId);
}