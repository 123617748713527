import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingFallback from './components/LoadingFallback';
import Layout from './components/layout/Layout';
import { useAnalytics } from './hooks/useAnalytics';

const HomePage = React.lazy(() => import('./pages/HomePage'));
const StripeProvider = React.lazy(() => import('./components/StripeProvider'));

function AppContent() {
  useAnalytics(); // Track analytics silently

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Layout>
  );
}

export default function App() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Router>
          <Suspense fallback={<LoadingFallback />}>
            <StripeProvider>
              <AppContent />
            </StripeProvider>
          </Suspense>
        </Router>
      </HelmetProvider>
    </ErrorBoundary>
  );
}